@import (reference) 'constants.less';

.align-bottom() {
    position: absolute;
    bottom: 0;
}

.cursor-pointer() {
    cursor: pointer !important;
}

.height-full() {
    height: 100%;
}

.no-padding() {
    padding: 0 !important;
}

.width-full() {
    width: 100%;
}

.width-auto() {
    width: auto !important;
}

.user-select-none() {
    -webkit-touch-callout: none; // iOS Safari
    -webkit-user-select: none; // Chrome/Safari/Opera
    -khtml-user-select: none; // Konqueror
    -moz-user-select: none; // Firefox
    -ms-user-select: none; // Internet Explorer/Edge
    user-select: none; // Non-prefixed version
}

.align-bottom {
    .align-bottom;
}

.cursor-pointer {
    .cursor-pointer;
}

.height-full {
    .height-full;
}

.height-third {
    height: 33.33333333%;
}

.no-padding {
    .no-padding;
}

.user-select-none {
    .user-select-none;
}

.width-full {
    .width-full;
}

// Text additions
.text-sticky-note {
    color: @stickyNote-bg-colour;
}

.text-error {
    color: @error-colour;
}

.text-error-light {
    color: @error-light-colour;
}
