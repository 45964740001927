.round-star-label (@colour, @size) {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	width: @size;
	height: @size;
	position: relative;
	border: none;
	font: normal 100%/normal Arial, Helvetica, sans-serif;
	color: rgba(0,0,0,1);
	text-align: center;
	-o-text-overflow: clip;
	-ms-text-overflow: clip;
	text-overflow: clip;
	background: @colour;

	&::before {
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
		width: @size;
		height: @size;
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		border: none;
		font: normal 100%/normal Arial, Helvetica, sans-serif;
		color: rgba(0,0,0,1);
		-o-text-overflow: clip;
		-ms-text-overflow: clip;
		text-overflow: clip;
		background: @colour;
		text-shadow: none;
		-webkit-transform: rotateZ(30deg);
		-moz-transform: rotateZ(30deg);
		-ms-transform: rotateZ(30deg);
		-o-transform: rotateZ(30deg);
		transform: rotateZ(30deg);
	}

	&::after {
		-webkit-box-sizing: content-box;
		-moz-box-sizing: content-box;
		box-sizing: content-box;
		width: @size;
		height: @size;
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		border: none;
		font: normal 100%/normal Arial, Helvetica, sans-serif;
		color: rgba(0,0,0,1);
		-o-text-overflow: clip;
		-ms-text-overflow: clip;
		text-overflow: clip;
		background: @colour;
		text-shadow: none;
		-webkit-transform: rotateZ(60deg);
		-moz-transform: rotateZ(60deg);
		-ms-transform: rotateZ(60deg);
		-o-transform: rotateZ(60deg);
		transform: rotateZ(60deg);
	}
}
