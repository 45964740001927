@import (reference) 'constants.less';
@import (reference) 'default.less';
@import (reference) '../../node_modules/bootstrap/less/variables.less';
@import (reference) '../../node_modules/bootstrap/less/mixins/border-radius.less';


/*
 * Global add-ons
 */

.sub-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #eee;
}


.navbar-static-top {
    margin-bottom: 0;
    border: 0;

    & + .container-fluid {
        height: ~'calc(100% - 55px)';
        overflow: hidden;
        padding: 0;

        & > .content {
            .height-full;
            position: relative;
        }
    }
}

.test-env .navbar-static-top + .container-fluid {
    height: ~'calc(100% - 92px)';
}

.test-env .navbar.navbar-fixed-top ~ .container > .content {
    padding-top: 108px;
}

.test-env .test-env-message {
    display: block !important;
}
/*
 * Sidebar
 */
/* Hide for mobile, show later */
.sidebar {
    @width-collapsed: 40px;
    @width-expanded-md: 200px;
    @width-expanded-lg: 300px;

    width: @width-collapsed;
    .height-full;
    position: absolute;
    background-color: @sidebar-background-colour;
    background-image: linear-gradient(@sidebar-background-colour, #FFF);
    border-right: 1px solid #eee;
    overflow-y: auto;
    overflow-x: hidden;

    ul.nav.nav-sidebar {
        .height-full;

        .subnav li > a {
            background-color: rgba(0,0,0,0.03);
            font-size: 0.9em;
        }

        li {
            @sidebar-link-border: solid 1px #DEDEDE;

            > a {
                padding: 10px;
                border-bottom: @sidebar-link-border;
                text-align: center;
            }

            &.active > a {
                &, &:hover, &:focus {
                    color: #fff;
                    background-color: #D83D84;
                }
            }

            &.summary {
                border-bottom: solid 4px #DEDEDE;
                position: relative;
                background-color: #FFF;
                padding: 10px;
            }

            &.summary,
            & > a > span:not(.fa-stack) {
                display: none;
            }

            & > a > span.fa-stack {
                width: 1em;
                height: 1em;
                line-height: 1em;

                > i.fa-stack-checkmark {
                    display: none;
                }

                &.show-checkmark {
                    > i.fa-stack-main-icon {
                        opacity: 0.8;
                    }

                    > i.fa-stack-checkmark {
                        display: initial;
                    }
                }
            }

            &.align-bottom {
                .align-bottom();

                & > a {
                    border-top: @sidebar-link-border;
                    border-bottom: none;
                }
            }
        }
    }

    & ~ .main-container {
        padding-left: @width-collapsed;
    }

    &.expanded {
        ul.nav.nav-sidebar {

            .subnav li > a {
                padding-left: 40px;
            }

            li {
                > a {
                    text-align: inherit;
                    padding: 10px 20px;
                    border-left: solid transparent 1.25rem;
                }

                &.active > a {
                    &, &:hover, &:focus {
                        border-left: solid @pink-light 1.25rem;
                    }
                }

                &:not(.summary) > a > i {
                    right: 12px;
                    position: absolute;
                    top: 12px;
                }

                &:not(.summary) > a > span.fa-stack {
                    right: 12px;
                    position: absolute;
                    top: 12px;
                }

                &.summary,
                & > a > span:not(fa-stack) {
                    display: inherit;
                }

                &.conversation {
                    .cursor-pointer;

                    > a > span {
                        display: inline;
                    }
                }
            }
        }

        width: @width-expanded-md;

        & ~ .main-container {
            padding-left: @width-expanded-md;
        }

        @media (min-width: @screen-4k-min) {
            width: @width-expanded-lg;

            & ~ .main-container {
                padding-left: @width-expanded-lg;
            }
        }
    }
}

/*@media (max-width: 480px) {
    .sidebar {
        width: 100% !important;
        position: relative !important;

        ul.nav.nav-sidebar {
            height: auto !important;

            li.align-bottom {
                display: none !important;
            }

            li.summary,
            li > a > span:not(.fa-stack) {
                display: initial !important;
            }
        }
    }
    .main-container {
        padding: 0 !important;
    }
}*/


.action-sidebar {
    bottom: 0;
    left: 0;
    z-index: 1000;
    overflow-x: hidden;
    background-color: @sidebar-background-colour;
    border-left: 1px solid #eee;
    margin-top: -16px;
    margin-right: -14px;
    text-align: right;
    min-height: 100vh;
    height: 100vh;
    background-image: linear-gradient( @sidebar-background-colour, #FFF );
}

.action-sidebar .action-content {
}

/* Sidebar navigation */

.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
}

/*  messaging */

.nav-sidebar > .newcontent > a,
.nav-sidebar > .newcontent > a:hover,
.nav-sidebar > .newcontent > a:focus {
    color: #428bca;
    background-color: #A9FF95;
}

.message-compose {
    background-color: #fff;
    border-color: #ddd;
    border-radius: 4px 4px 0 0;
    border-width: 1px;
    box-shadow: none;
    margin-left: 0;
    margin-right: 0;
}

/*
 * Main content
 */

.main-container {
    .height-full;
    padding: 0;

    .main-content {
        .height-full;
        overflow-y: auto;
        overflow-x: hidden;

        .page-header {
            margin-top: 0;
        }

        padding: 10px;

        @media (min-width: @screen-xlg-min) {
            padding: 20px;
        }
        
    }
}

/* 
 * Applications grid 
 */
.appGrid, .workqueueGrid {
    padding: 0;
    position: relative;
    overflow-x: auto;
    width: 100%;
    height: 100%;

    .gridOverlay {
        position: absolute;
        width: 100%;
        height: 100%;
        text-align: center;
        background-color: rgba(255,255,255,0.6);
        z-index: 1;

        span {
            display: block;
            position: absolute;
            height: 22px;
            line-height: 22px;
            font-size: @font-size-enormous;
            margin-top: -11px;
            width: 120px;
            margin-left: -60px;
            top: 50%;
            left: 50%;
        }
    }

    .gridContent {
        height: 100%;
    }

    .gridTitle {
        overflow: hidden;
        margin-left: 48px;

        h3 {
            margin-top: 22px;
            margin-bottom: 22px;
            display: inline-block;
            vertical-align: middle;
        }

        .btn-group {
            margin-left: 40px;
        }
    }

    .gridPagination {
        position: absolute;
        top: 0;
        right: 0;
        width: 460px;
        text-align: right;
        padding-right: 6px;
    }


    .gridContainer {
        overflow: auto;
        height: calc(100% - 74px);

        .tr-ng-grid {
            margin-bottom: 0;
        }

        tfoot {
            display: none;
        }
    }
}

.btn-group.btn-group-rounded {

    :first-child {
        .border-left-radius(@border-radius-large);
    }

    :last-child {
        .border-right-radius(@border-radius-large);
    }
}

legend .btn-group {
    padding: 6px;
}
/*
 * Placeholder dashboard ideas
 */
.placeholders {
    margin-bottom: 30px;
    text-align: center;

    h4 {
        margin-bottom: 0;
    }
}

.placeholder {
    margin-bottom: 20px;

    img {
        display: inline-block;
        border-radius: 50%;
    }
}

.datedetail {
    display: block;
    float: right;
}

.tr-ng-grid {
    font-size: @font-size-smaller;

    .tr-ng-column-header .tr-ng-title {
        padding-top: 0;
        padding-right: 12px;
    }

    > thead > tr > td,
    > tbody > tr > th {
        &, & > .tr-ng-cell {
            .cursor-pointer;
        }
    }
}

.tr-ng-grid .video-upload-prompt {
    background-color: red;
}

.navbar-form {
    margin-right: 5px;
}

.pagination {
    > .active > a,
    > .active > span {
        &, &:hover, &:focus {
            border: solid @pink-light 1px;
            background-color: #D83D84;
        }
    }
}

.app-progress {
    min-width: 112px;
}

.modal {
    background-color: rgba(0,0,0,0.5);
}

.appGridMenu {
    padding: 0.6em 0.5em 0;
}

.selectionAwareBtn {
    width: 95%;
    margin-right: 1em;
}

.selectInterviewerModal {
    padding: 0 15px;
}

.modalInstructions h2 {
    margin-top: 0;
}

.table-hover > tbody > tr:hover {
    cursor: pointer;
}

.mini_profile {
    display: block;
    height: 70px;
    width: 70px;
    /*background-color: red;*/
    float: left;
    clear: both;
    margin-right: 8px;
}



@side-menu-border: darken(@sidebar-background-colour, 10%);
@side-menu-selected: darken(@sidebar-background-colour, 40%);

.tab-menu {
    list-style: none;
    padding: 0;
    text-align: center;
    margin: 12px 0;

    li {
        display: inline;
        position: relative;

        a {
            padding: 6px 10px;
            margin: 2px;
            border: 1px solid @side-menu-border;
            color: @side-menu-selected;
            display: inline-block;

            &:hover {
                background-color: @side-menu-border;
            }

            &.active {
                color: @sidebar-background-colour;
                background-color: @side-menu-selected;
            }
        }
    }
}

.page {
    position: relative;
}

.page-panel {
    padding-right: 300px;
}

//todo - rename this to something more meaningful
.page-sidebar {
    @page-sidebar-width-full-md: 200px;
    @page-sidebar-width-full-lg: 350px;
    @page-sidebar-document-preview-width-md: 500px;
    @page-sidebar-document-preview-width-lg: 700px;
    @page-sidebar-uploads-width-md: 200px;
    @page-sidebar-uploads-width-lg: 350px;

    height: 100% !important;
    float: right;
    padding: 10px 0;
    z-index: 1000;
    //overflow-x: hidden;
    background-color: @sidebar-background-colour;
    border-left: 1px solid #eee;
    text-align: right;
    background-image: linear-gradient(@sidebar-background-colour, #FFF);
    overflow-y: auto;

    &.document-preview {
        @media (min-width: @screen-md-min) {
            width: @page-sidebar-document-preview-width-md;
        }

        @media (min-width: @screen-4k-min) {
            width: @page-sidebar-document-preview-width-lg;
        }

        .document-preview-content {
            display: block;
            padding: 10px;

            img {
                width: 100%;
                border: @side-menu-border;
            }
        }

        &.open ~ .page-sidebar-label {
            right: @page-sidebar-document-preview-width-md;
        }

        & ~ .main-container {
            @media (min-width: @screen-4k-min) {
                padding-right: @page-sidebar-document-preview-width-lg;
            }
        }
    }

    &.uploads {
        /*@media (min-width: @screen-md-min) {
            width: @page-sidebar-uploads-width-md;
        }*/
        @media (min-width: @screen-4k-min) {
            width: @page-sidebar-uploads-width-lg;
        }

        .uploads-content {
            display: block;
            padding: 10px;
            overflow: auto;

            img {
                width: 100%;
                border: @side-menu-border;
            }

            .uploads-content-status-rows {
                margin-bottom: 10px;
            }

            .uploads-content-status-rows:last-child {
                margin-bottom: 0;
            }

            .uploads-content-status-row {
                cursor: default;
                margin-top: 10px;
            }
        }
        /*&.open ~ .page-sidebar-label {
            right: @page-sidebar-uploads-width-md;
        }*/
        & ~ .main-container {
            @media (min-width: @screen-4k-min) {
                padding-right: @page-sidebar-uploads-width-lg;
            }
        }
    }

    &.uploads.documentOpen {
        @media (min-width: @screen-md-min) {
            width: @page-sidebar-document-preview-width-md;
        }

        @media (min-width: @screen-4k-min) {
            width: @page-sidebar-document-preview-width-lg;
        }

        &.open ~ .page-sidebar-label {
            right: @page-sidebar-document-preview-width-md;
        }

        & ~ .main-container {
            @media (min-width: @screen-4k-min) {
                padding-right: @page-sidebar-document-preview-width-lg;
            }
        }
    }

    width: @page-sidebar-width-full-lg;
    display: none;

    &.open {
        display: block;
        right: 0;
        overflow-y: visible;
        overflow-x: hidden;

        @media (min-width: @screen-xs) and (min-height: 1045px) {
            //overflow-y: scroll; // TODO relative to other sizes - for now this workaround is fine
            height: 100% !important;
        }

        @media (min-width: @screen-xs) {
            overflow-y: auto; // TODO relative to other sizes - for now this workaround is fine
            height: 90% !important;
            width: 290px;
        }

        @media (min-width: @screen-xs) and (max-height: 1045px) and (min-height:530px) {
            overflow-y: auto; // TODO relative to other sizes - for now this workaround is fine
            height: 90% !important;
            width: 290px;
        }

        @media (min-width: @screen-md-min) {
            width: 350px;
        }

        @media (min-width: @screen-xs) and (max-height: 530px) {
            overflow-y: scroll; // TODO relative to other sizes - for now this workaround is fine
            height: 75% !important;
        }

        @media (min-width: @screen-xs) and (max-height: 262px) {
            overflow-y: scroll; // TODO relative to other sizes - for now this workaround is fine
            height: 65% !important;
        }
    }

    & ~ .page-sidebar-label {
        position: fixed;
        right: 0;
        z-index: 1000;
        width: 39px; // TODO relative to other sizes - for now this workaround is fine
        @media (min-width: @screen-lg-min) {
            width: 41px; // TODO relative to other sizes - for now this workaround is fine
        }

        > ul.nav.nav-tabs {
            top: 50px;
            position: absolute;
            -webkit-transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
            -o-transform: rotate(-90deg);
            transform: rotate(-90deg);

            > li > a {
                background-color: @pink-light;
                color: white;

                &:hover, &:active {
                    background-color: @pink-strong;
                }
            }
        }
    }

    &.open ~ .page-sidebar-label {
        right: @page-sidebar-width-full-lg;

        > ul.nav.nav-tabs > li > a {
            background-color: @pink-strong;
        }

        @media (min-width: @screen-xs) {
            right: 290px;
        }

        @media (min-width: @screen-md-min) {
            right: 350px;
        }
    }

    @media (min-width: @screen-4k-min) {
        display: block;

        & ~ .page-sidebar-label {
            display: none;
        }

        & ~ .main-container {
            padding-right: @page-sidebar-width-full-lg;
        }
    }
    /*@media (min-width: @screen-4k-min) {
        width: @page-sidebar-width-full-lg;

        & ~ .main-container {
            padding-right: @page-sidebar-width-full-lg;
        }
    }*/
}


.actions .actions-content,
.profile .profile-content,
.comments .comments-content,
.uploads .uploads-content,
.finance .finance-content,
.document-preview .document-preview-content {
    display: block;
    cursor: pointer;
}

.profile {
    .profile-content {
        text-align: left;

        .commentHeading {
            margin-left: 2px;
            font-weight: bold;
        }

        .marginTop {
            margin-top: 10px;
        }

        .profile-container {
            padding: 25px;
        }
    }
}


.tab-menu-item {
    display: none;
}


.comments {
    .comments-content {
        text-align: left;
        padding: 0px 5px 15px 5px;
        height: ~'calc(100% - 70px)'; // hardcoded for now
        .commentHeading {
            margin-left: 2px;
            font-weight: bold;
        }

        p {
            margin-bottom: 1px;
        }

        .commentBackground {
            background-color: white;
            height: ~'calc(100% - 91px - 14px - 21px)'; // hardcoded for now
            overflow-y: auto;

            .comment {
                margin-bottom: 8px;

                .comment-heading {
                    font-weight: bold;
                }

                .comment-text {
                    white-space: pre-wrap;
                }
            }
        }

        .marginTop {
            margin-top: 3px;
        }
    }
}

.document_UploadedStatusTitle {
    margin-bottom: 10px;
    font-weight: bold;
}

.uploads {
    .uploads-content {
        text-align: left;
        padding: 0px 5px 15px 5px;
        height: ~'calc(100% - 70px)'; // hardcoded for now
        .uploadsBackground {
            background-color: white;
            height: ~'calc(100% - 91px - 14px - 21px)'; // hardcoded for now
            overflow-y: auto;

            .upload-heading {
                font-weight: bold;
            }

            .upload {
                margin-bottom: 8px;
                color: black;

                .upload-heading {
                    font-weight: bold;
                }

                .columnStatus {
                    margin: 0 0 10px;
                }

                .colorTriangle {
                    color: red;
                }

                .spanToTheRight {
                    padding-right: 10px;
                }
            }
        }

        .marginTop {
            margin-top: 3px;
        }
    }
}

.display-document {

    .document {
        margin-top: 12px;
        border: 1px solid #eee;
    }


    .no-document {
        height: 520px;
        background-color: #fff;
        border: 1px solid #eee;
        text-align: center;
        display: table;
        width: 100%;

        span {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

.activity-feed .activity {
    border-bottom: 1px solid #eee;
    padding: 5px 6px 2px 6px;
    overflow: hidden;

    .avatar-small {
        width: 30px;
        height: 30px;
        border: 1px #ccc solid; // hardcodded for now //TODO - move to constant!
        border-radius: 3px;
        display: inline-block;
        background-position: center;
        /* Make the background image cover the area of the <div>, and clip the excess */
        background-size: cover;
    }

    .user-name {
        font-weight: bold;

        &.user-name-staff {
            color: @pink-strong;
        }
    }

    .activity-text {
        .width-full;
        display: inline;
    }

    .activity-date {
        font-size: smaller;
    }
}
/* taken from here: http://callmenick.com/post/css-toggle-switch-examples */
.settings {
    display: table;
    width: 100%;

    .row {
        display: table-row;
        margin-top: 10px;
    }

    .questiontext {
        width: 150px;
        text-align: left;
        padding: 10px;
        display: table-cell;
        vertical-align: middle;

        @media (min-width: @screen-xs) {
            padding: 0px;
            padding-bottom: 1em;
            padding-top: 1em;
            padding-left: 10px;
        }
    }

    .switch {
        display: table-cell;
        vertical-align: middle;
        padding-right: 5px;
    }

    .office-user-btn {
        float: left;
        display: block;
        width: 160px;

        @media (min-width: @screen-xs) {
            margin-right: 5px;
        }
    }
    /*@media (max-width: @screen-4k-min) {
    .questiontext {
        display: block;
        padding-bottom: 0px;
    }

    .office-user-btn {
        margin-left: 20px;
        margin-bottom: 10px;
    }
}*/
}

.cmn-toggle {
    position: absolute;
    margin-left: -9999px;
    visibility: hidden;
}

.cmn-toggle + label {
    display: block;
    position: relative;
    cursor: pointer;
    outline: none;
    user-select: none;
}

input.cmn-toggle-round-flat + label {
    padding: 2px;
    width: 50px;
    height: 28px;
    background-color: #dddddd;
    border-radius: 28px;
    transition: background 0.4s;
}

input.cmn-toggle-round-flat + label:before,
input.cmn-toggle-round-flat + label:after {
    display: block;
    position: absolute;
    content: "";
}

input.cmn-toggle-round-flat + label:before {
    top: 2px;
    left: 2px;
    bottom: 2px;
    right: 2px;
    background-color: #fff;
    border-radius: 20px;
    transition: background 0.4s;
}

input.cmn-toggle-round-flat + label:after {
    top: 4px;
    left: 4px;
    bottom: 4px;
    width: 24px;
    background-color: #dddddd;
    border-radius: 28px;
    transition: margin 0.4s, background 0.4s;
}

input.cmn-toggle-round-flat:checked + label {
    background-color: #8ce196;
}

input.cmn-toggle-round-flat:checked + label:after {
    margin-left: 18px;
    background-color: #8ce196;
}

ul.attendee-list {
    display: inline-block;
    padding-left: 0;
    list-style: none;

    li.event-attendee {
        display: inline-block;

        &:not(:first-child):before {
            content: ', ';
        }
    }
}

.host-family-page #divSearch {
    display: none !important;
}


li.no-search-result {
    display: block;
}

li + li.no-search-result {
    display: none;
}


.custom-popup-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    background-color: #f9f9f9;
}

.custom-popup-wrapper .message {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    color: #868686;
    margin: 0;
    width: 100%;
    border-radius: 0;
}

.custom-popup-wrapper .dropdown-menu {
    position: static;
    float: none;
    display: block;
    min-width: 160px;
    background-color: transparent;
    border: none;
    border-radius: 0;
    box-shadow: none;
}

.searchType {
    text-align: center;
    padding: 10px;
}

.reports-list {
    float: left;
    padding: 5px;
    width: 260px;
    height: 100%;
    box-shadow: inset 0 0px 1px rgba(255, 255, 255, .15), 0 1px 5px rgba(0, 0, 0, .075);
    border-right: 1px solid #EFEEEC;

    .btn {
        display: block;
        width: 100%;
        margin-bottom: 5px;
        text-align: left;
        white-space: normal;
    }
}

.report {
    height: 100%;

    iframe {
        width: 100%;
        height: 100%;
        border: none;
    }
}


.uploaded-status .heading {
    padding: 6px;
}

.insurance-selection {
    margin-left: -15px;
    margin-right: -15px;
}

.insurance-selection tr.disabled {
    cursor: auto !important;
    text-decoration: line-through;
}

.sidebar {
    fieldset {
        padding: 0.5em .625em 0;
        border: 1px solid #c0c0c0;
        margin: 8px 4px;
        border: 1px solid #c0c0c0;
    }

    legend {
        display: inline;
        width: auto;
        margin-bottom: 0;
        border: none;
        font-size: 13px;
        font-weight: bold;
    }

    label {
        font-weight: normal;
    }
}

.form-group-sm .input-group button.btn-default {
    font-size: 12px;
    padding-top: 5px;
}

.filter-progress .control-label {
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    i {
        padding-right: 4px;
    }
}

.dropdown-menu > li > a.typeahead-show-all {
    padding: 12px;
    text-align: center;
}

.margin-top-25{
    margin-top:25px;
}

