.panel(@colour) {
    border-color: @colour;

    & > .panel-heading {
        color: #fff;
        background-color: @colour;
        background-image: none;
        border-color: @colour;

        & + .panel-collapse > .panel-body {
            border-top-color: @colour;
        }

        .badge {
            color: #f5f5f5;
            background-color: #333;
        }
    }

    & > .panel-footer + .panel-collapse > .panel-body {
        border-bottom-color: @colour;
    }
}
